<template>
  <div class="ui stackable grid row dimmed notification">
    <div class="ui sixteen wide notification column">
      <div class="ui notifications container">
        <header class="col-xs-12 text-left notification-header">
          <h2 class="h1">Notifications</h2>
        </header>

        <main class="notifications-container">
          <div class="ui container">
            <AccordionList
              v-if="notificationsList && notificationsList.length"
              @show-notification="showNotification"
              @update-notification="onUpdateNotificationStatus"
              :itemsList="notificationsList"
              :hasNotificationUpdateButton="true"
            />
            <div class="notifications-empty-container" v-else>
              <img src="@/assets/images/notifications-empty.svg" width="256" height="220" alt="empty notification" />
              <p>
                Looks like you haven’t
                <br />received any notifications yet
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { notificationMessagesRoutine } from '@/store/modules/notificationMessages/routines'
import { getNotifications, updateNotificationStatus } from '@/api/companyNotification'
import AccordionList from '@/components/AccordionList'
import { sidebarGetters } from '../../store/modules/sidebar/routines'

export default {
  name: 'Notifications',
  components: {
    AccordionList
  },
  data() {
    const tableColumns = ['icon', 'description', 'creationDate', 'actions']
    const columnsClasses = tableColumns.reduce((acc, current) => ({ ...acc, [current]: current }), {})
    return {
      notificationsList: null,
      tableColumns,
      tableOptions: {
        uniqueKey: 'id',
        skin: 'table table-v-align swoop-grid',
        filterable: false, // hide filtering
        showSorter: false, // show custom sorter component
        headings: {
          icon: 'Status',
          creationDate: 'Date',
          actions: ''
        },
        sortable: ['description', 'creationDate'],
        orderBy: {
          column: 'creationDate',
          ascending: false
        },
        columnsClasses
      }
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      isSideBarOpen: sidebarGetters.SIDEBAR_STATUS
    }),
    unreadNotificationsCount() {
      if (!this.notificationsList) return null
      return this.notificationsList.reduce((acc, notification) => (notification.isViewed ? acc : acc + 1), 0)
    }
  },
  methods: {
    ...mapActions({
      getAnyUnreadNotification: notificationMessagesRoutine.TRIGGER
    }),
    fillNotificationsList() {
      return getNotifications(this.companyId)
        .then(res => {
          const notifications = res.data

          notifications &&
            notifications.length > 0 &&
            notifications.forEach(notification => {
              notification.clicked = false
            })

          this.notificationsList = notifications
        })
        .catch(() => {
          this.notificationsList = []
        })
    },
    hackPagination(page) {
      this.$refs.notifications.$children.filter(component => component.setPage).forEach(pagination => (pagination.Page = page))
    },
    async showNotification(item) {
      item.clicked = !item.clicked
      if (!item.isViewed) {
        await this.onUpdateNotificationStatus(item)
      }
    },
    async onUpdateNotificationStatus(item) {
      await updateNotificationStatus(item.id)
      item.isViewed = true
    }
  },
  created() {
    this.fillNotificationsList()
    this.getAnyUnreadNotification()
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';
.ui.notification {
  .ui.notification.column {
    width: 100%;
    @media only screen and (max-width: $breakpoint-xs-max) {
      padding: 1rem 0rem;
    }
  }
  .ui.notifications {
    // padding: 1rem;
    @media only screen and (max-width: $breakpoint-xs-max) {
      padding: 0rem;
    }
    .notification-header {
      // padding: 40px 0 35px 60px;
      padding: 3rem 2rem 1rem;
      color: var(--color-primary-500);
      border-bottom: 1px solid #e8eff8;
      margin-bottom: 28px;
      @media only screen and (max-width: $breakpoint-xs-max) {
        padding: 1rem;
      }

      h2 {
        font-weight: normal;
      }
    }
    .notifications-container {
      padding: 1rem;
      width: 100%;
      .container {
        margin-left: 0;
      }
    }

    .notifications-empty-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      p {
        color: var(--color-primary-500);
        font-size: 20px;
      }
    }
  }
  .VueTables {
    /deep/ {
      td {
        &.icon {
          width: 64px;
          .notification-icon {
            &.is-viewed {
              opacity: 0.5;
            }
          }
        }
        &.actions {
          width: 100px;
        }
      }
    }
  }
}
</style>
